.App-header {
    background-color: #00305C;
    height: 70px;
    width:100vw;
    display: flex;
    align-items: center;
    color: white;
    box-shadow:rgb(220,220,220) 0px 2px 10px;
}

.App-logo {
    height: 28px;
    margin-left:20px;
    margin-bottom:10px;
}

.title{
    font-size:20px;
    font-weight:bold;
    margin:0px;
}
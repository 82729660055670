@import '~antd/dist/antd.css';

.sat-setting {
    width: 100%;
    text-align: left;
    padding: 10px 15px 0 15px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
}

.setting-list {
    margin-bottom: 8px;
}

.show-nearby {
    display: flex;
    justify-content: center;
    margin-top:2px;
}

.show-nearby .ant-form-item-control {
    text-align: center;
}

.sat-setting .ant-form-item {
    margin-bottom: 12px;
}


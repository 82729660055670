.sat-list-box {
    border: 2px solid #ccc;
    height: 380px;
    margin-top: 10px;
    padding: 10px;
}

.btn-container {
    text-transform: none;
    padding: 10px 36px;
    background-color: #2bb673;
    font-size: 1em;
    color: #fff;

}

.sat-list {
    height: 300px;
    overflow: scroll;
}

.spin-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 335px;
}
